import React, { useState,useEffect } from 'react';
function Terms() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='page-warp contactpage'>
      <section className="py-5 gradiant text-center">
        <h1 className='text-light'>Terms & Conditions</h1>
        <p className='text-light'>   Acceptance of Terms </p>

      </section>
      <section>
        <div className='container terms mb-4'>
          <div className='row'>
          <p className=' mt-3'>
            <ol>
              <li className='fw-noraml'>
Welcome to Brinda Corporation's website. By accessing or using this website, 
you agree to comply with and be bound by the following terms and 
conditions. If you do not agree to these terms and conditions, please do not 
use this website. </li>

<li> Company Information
Brinda Corporation is an ISO 9001:2000-certified company that provides 
information technology solutions, including infrastructure solutions and 
application deployment. Our mission is to offer comprehensive technology 
solutions to our clients. </li>
<li> Use of Website 

<ol type="a">
  <li>
  You may use this website for informational purposes only. Any 
unauthorized use of the website is strictly prohibited. 
  </li>
  <li>
  You agree not to reproduce, distribute, modify, or otherwise exploit any 
content on this website without the prior written consent of Brinda 
Corporation.
  </li>
</ol>

</li>



  
  <li>  Disclaimer 
    <ol type="a">
      <li >
      Brinda Corporation makes no representations or warranties of any kind, 
express or implied, about the accuracy, reliability, suitability, or availability of 
the information contained on this website. You acknowledge that any 
reliance on such information is at your own risk. </li>
<li >
Brinda Corporation reserves the right to modify or discontinue any aspect 
of this website at any time without prior notice. 
      </li>
    </ol>
    </li>

<li> Privacy Policy 
  <ol type="a">
    <li>
    Your use of this website is also governed by our Privacy Policy, which can 
be found [here](link to privacy policy). Please review this policy to 
understand how we collect, use, and protect your personal information.
    </li>
  </ol>
</li>
<li>Intellectual Property
  <ol type="a">
<li>
All content, including text, graphics, logos, images, and software, on this 
website is the property of Brinda Corporation and is protected by copyright 
and other intellectual property laws. 
</li>
<li>
You may not use our trademarks, service marks, or logos without our prior 
written consent
</li>
  </ol>

</li> 
<li> Links to Third-Party Websites 
  <ol type="a">
    <li>
    This website may contain links to third-party websites. These links are 
provided for your convenience, and Brinda Corporation is not responsible 
for the content or reliability of any linked websites. 
    </li>
  </ol>


</li>
<li> Limitation of Liability 
  <ol type="a">
    <li>
Brinda Corporation and its affiliates shall not be liable for any direct, 
indirect, incidental, special, or consequential damages arising out of or in any 
way connected with your use of this website. 
</li>
</ol>
</li>
<li>
 Contact Information<br/>
If you have any questions or concerns about these terms and conditions, 
please contact us at:<br/>
<span className='fw-bold fs-6'>
Brinda Corporation
<br/>
302. 3rd Floor, Sheetla House <br/>
No.73-74, Nehru Plac,<br/> New Delhi 
110019 
<br/>
+91 9818176405 <br/>
sales@thebcplgroup.com
</span>
</li>

<li> Changes to Terms and Conditions 
<ol type="a">
  <li>
   Brinda Corporation reserves the right to update or revise these terms and 
conditions at any time. Please check this page periodically for changes. Your 
continued use of the website after the posting of any changes constitutes 
acceptance of those changes.
</li>
</ol>

</li>
</ol>
<strong >
By using this website, you acknowledge that you have read, understood, and 
agreed to these terms and conditions.  <br/>
Last Updated: 10-Sep-2023
</strong>
</p>
          </div>
        </div>
      </section>
    </div >
  );
}
export default Terms;