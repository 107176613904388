import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
function Partners() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [data, setData] = useState(null);
  // const apiUrl = "https://webadmin.alexerp.com/api/APIServices/GetPortfolioData?BranchCode=Brinda&AccessKey=undbnTNU1qWFJQuJMXicynH6c";
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/APIServices/GetPartnersData?Type=Partners&BranchCode=Brinda&AccessKey=${process.env.REACT_APP_ACCESS_KEY}`;
  const userId = "thebcplgroup";
  const password = "http.com";
  const credentials = btoa(`${userId}:${password}`);
  useEffect(() => {
    fetch(apiUrl, {
      headers: {
        Authorization: `Basic ${credentials}`
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      // .then(data => setData.split(data,'\\r\\n'))
      
      .catch(error => console.error("Fetch error:", error));
  }, []);
  return (
    <div className='page-warp contactpage'>
      <section className="py-5 gradiant text-center">
        <h1 className='text-light'>Partners</h1>
        <p className='text-light mt-3'>Our partners programme can be divided into four categories. <br/> They are:
Business Partners, Technology Partners, Solutions Partners, Consulting Partners.</p>
      </section>
      <section className="py-100">
         {data ? ( <div className="container">
          <div className='row'>
                {data.map((item) => {
                  return (
                    <div className="col-md-2 col-lg-2 mb-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="2000">
                    <div className="partner__main" title={item.Descriptions} >
                      <img src={item.PhotoName} alt="logo" />
                    </div>
                    {/* <pre>{item.Descriptions}  </pre> */}
                  </div>
                  )
                })}
              </div>
              </div>
            ) : (
              <p>Loading data...</p>
            )}
        </section>
      {/* <section className="py-100 ">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="200" data-aos-duration="2000">
              <div className="partner__main">
                <img src="assets/images/logo/logo__1.svg" alt="logo" />
              </div>
            </div>
            <div className="col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="300" data-aos-duration="2000">
              <div className="partner__main">
                <img src="assets/images/logo/logo__2.svg" alt="logo" />
              </div>
            </div>
            <div className="col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000">
              <div className="partner__main">
                <img src="assets/images/logo/logo__3.svg" alt="logo" />
              </div>
            </div>
            <div className="col-md-4 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000">
              <div className="partner__main">
                <img src="assets/images/logo/logo__3.svg" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </section > */}
    </div >
  );
}
export default Partners;