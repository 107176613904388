import React, { Component, useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
export default function CarouselFade() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
     const [currentPage, setCurrentPage] = useState('home'); // Set the initial active page
     // Function to update the active page
     const handlePageChange = (page) => {
        setCurrentPage(page);
     };
    return (
        <Carousel className='slider__section' >
            <Carousel.Item interval={2500} style={{height:"90vh"}}>
                <img
                    className="d-block w-100 h-100"
                    src="assets/images/bnner_tech.jpg"
                    alt="Image One"
                />
                <Carousel.Caption>
                <h1 data-aos="fade-up" >Welcome  to  <br />BCPL Technologies</h1>
                    <p className='banner__subtitle '>Brinda Corporation an ISO 9001:2000-certified company, is a
                           broad-based
                           information technology
                           solutions provider. It offers a comprehensive understanding of infrastructure solutions and
                           application deployment.</p>
                           {/* <Link to="/Contact" onClick={() => handlePageChange('contact')} >
                           <button data-aos="fade-up"  className="btn btn btn-primary mt-3 btn_shadow" >
                            Get Started  </button> 
                            </Link> */}
                </Carousel.Caption>
            </Carousel.Item >
            <Carousel.Item  interval={2500} style={{height:"90vh"}}>
                <img
                    className="d-block w-100 h-100"
                    src="assets/images/bnner_tech2.jpg"
                    alt="Image Two"
                />
                <Carousel.Caption >
                <h1 data-aos="fade-up"  >Welcome  to  <br />BCPL Technologies</h1>
                <p className='banner__subtitle '>Brinda Corporation an ISO 9001:2000-certified company, is a
                           broad-based
                           information technology
                           solutions provider. It offers a comprehensive understanding of infrastructure solutions and
                           application deployment.</p>
                           {/* <Link to="/Contact" onClick={() => handlePageChange('contact')} >
                           <button data-aos="fade-up"  className="btn btn btn-primary mt-3 btn_shadow" >
                            Get Started  </button> 
                            </Link> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2500} style={{height:"90vh"}}>
                <img
                    className="d-block w-100 h-100"
                    src="assets/images/Technical-Innovation.png"
                    alt="Image Two"
                />
                <Carousel.Caption >
                <h1 data-aos="fade-up"  >Welcome  to  <br />BCPL Technologies</h1>
                <p className='banner__subtitle '>Brinda Corporation an ISO 9001:2000-certified company, is a
                           broad-based
                           information technology
                           solutions provider. It offers a comprehensive understanding of infrastructure solutions and
                           application deployment.</p>
                           {/* <Link to="/Contact" onClick={() => handlePageChange('contact')} >
                           <button data-aos="fade-up"  className="btn btn btn-primary mt-3 btn_shadow" >
                            Get Started  </button> 
                            </Link> */}
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}