import React, { useState,useEffect } from 'react';
import ClientsHome from './clientHome'
function About() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='page-warp contactpage'>
        <section className="py-5 gradiant text-center">
          <div className='container'>
        <h1 className='text-light'>About  Us</h1>
        <p className='text-light mt-3'>
        YOU CAN FOCUS ON GROWING YOUR BUSINESS WHILE PARTNERING WITH BCPL FOR YOUR IT INFRASTRUCTURE, DATA CENTER, IT STRATEGY, DATA BACKUP, DISASTER RECOVERY, & CLOUD COMPUTING NEEDS</p>
        </div>
      </section>
      <section className="padding-y">
        <div className="container">
          <h2 data-aos="fade-up" className="mb-3">Our Guiding Principles</h2>
          <p className="mb-3 text_jus" data-aos="fade-up" data-aos-delay="300" data-aos-duration="2000">We are a fast-growing company of highly skilled, passionate IT professionals with excellence in service delivery, enabling faster innovation and higher productivity. Our services are dynamic, scalable, resilient and responsive. We take a highly collaborative approach to align our services with your business goals.</p>
          <p className="mb-3 text_jus" data-aos="fade-up" data-aos-delay="400" data-aos-duration="2000">
            Top information technology solution provider of cloud, data centers, disaster recovery, Data security, IT Infrastructure, IT Managed services, Automation, AI, Machine learning & Surveillance security.
          </p>
         
          <p className="mb-3 text_jus" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
            We strive to become an extension of your IT department and we will augment the strengths and capabilities of your company’s IT experts, help you manage today’s challenges, and guide you to efficient and informed choices for tomorrow. Our extensive knowledge of data management, IT infrastructure, data backup, and all other aspects of today’s IT landscape allows us to provide much more than just solutions: BCPL provides IT INTELLIGENCE.</p>
          <p className="mb-3 text_jus" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
            BCPL is not your typical big box information technology solutions and managed IT services provider (MSP). We test, implement, and support the solutions we represent. Plus, our highly trained and experienced engineers truly understand core technologies and integration inside and out. We keep a pulse on how the IT industry’s advancements can bring value to our clients.</p>
          <p className="mb-3 text_jus" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
            With today’s explosive growth in data, many organizations are struggling to manage, move, and protect their burgeoning information assets while mitigating risk and complying with new regulations. There are typically very few IT personnel assets who have even less time to devote to developing new solutions which means that today’s IT departments are expected to do more with less resources. Yet, managing for today and planning for tomorrow must go hand-in-hand.</p>
          <p className="mb-3 text_jus" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
            BCPL understands the demands and pressure put on executives and IT professionals. BCPL can help fill the gaps in your company’s IT infrastructure, storage, data protection, cloud computing, and IT strategy solutions. We will ease some of that burden as your partner and information technology solutions and services provider.</p>
          <p className="mb-3 text_jus" data-aos="fade-up" data-aos-delay="500" data-aos-duration="2000">
            We will guide your organization through the entire solution process and educate your staff on the technologies that will be truly beneficial to solving your key business challenges.</p>
        </div>
      </section>
      <section className="padding-y bg">
        <div className="container">
          <div className="row align-items-center justify-content-between g-5">
            <div className="col-md-6">
              <div className="row align-items-end">
                <div className="col-md-6 h-100">
                  <div className="misstion_img1 misstion_img mt-3" data-aos="fade-up" data-aos-duration="2000">
                    <img src="assets/images/about/about_2.jpg" alt="our-misstion" className="" />
                  </div>
                </div>
                <div className="col-md-6 h-100 ">
                  <div className="misstion_img2 misstion_img mb-3" data-aos="fade-up" data-aos-duration="2000"
                    data-aos-delay="300">
                    <img src="assets/images/about/about_1.jpg" alt="our-misstion" className="" />
                  </div>
                  <div className="misstion_img2 misstion_img" data-aos="fade-up" data-aos-delay="400"
                    data-aos-duration="2000">
                    <img src="assets/images/about/Biometric-Access-Control.png" alt="our-misstion" className="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="our-misstion-div gradiant" data-aos="fade-up" data-aos-duration="2000">
                <h2 className=" mb-4 text-white" data-aos="fade-up" data-aos-delay="300"
                  data-aos-duration="2000">
                  What we Value</h2>
                <p className="text-white" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">We value our client’s trust in us. Trust to keep them up and running, trust to be there at 3 AM when things aren’t going right, and trust to keep them ahead of their competition.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding-y">
        <div className="container">
          <div className="row align-items-center g-5">
            <div className="col-md-8">
              <h2 className=" mb-4" data-aos="fade-up" data-aos-duration="2000">A Leading Managed IT Services & Solutions Peovider Who Understand Technology Who is BCPL ?</h2>
              <p data-aos="fade-up" className='text_jus' data-aos-delay="300" data-aos-duration="2000">Genient Life is Brinda Corporation Pvt Ltd. is a high-touch managed IT services and solutions provider. BCPL’s focus is on the management, movement, and protection of data. Our “high-touch” philosophy means that we invest in the necessary consulting time during the discovery phase to understand our clients’ business challenges and vision.</p>
              <p data-aos="fade-up" className='text_jus' data-aos-delay="300" data-aos-duration="2000">BCPL comes to understand the business drivers and how they support applications and underlying IT infrastructure. More importantly, we design ways to positively impact those drivers to ultimately improve our clients’ business as a whole. BCPL offers end-to-end solutions in infrastructure, integration, cloud strategy and migration, data protection-as-a-service, data and disaster recovery, IT storage, post-installment support, and managed IT services, just to name just a few.</p>
              <p data-aos="fade-up" className='text_jus' data-aos-delay="300" data-aos-duration="2000">BCPL is an expert in IT infrastructure and we can architect a server, storage, networking, wireless, or cloud solution of your choosing. We work with the leading technology vendors such as HPE, Dell, Lenovo, VMware, Acronis, Veeam, Synology Storage, Cisco, Fortinet, and many more!</p>
            </div>
            <div className="col-md-4">
              <img src="assets/images/about/about-banner-2.png" data-aos="fade-left" data-aos-duration="2000" />
            </div>
          </div>
        </div>
      </section>
      <section >
        <div className='container'>
      <h2 className=" mb-4" data-aos="fade-up" data-aos-duration="2000">Our Clients</h2>
    <ClientsHome/>
    </div>
      </section>
    </div>
  );
}
export default About;