import React, { Component } from 'react';
class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
      Name: '',
      EmailID: '',
      PhoneNumber: '',
      BranchCode: 'Brinda',
      AccessKey: process.env.REACT_APP_ACCESS_KEY,
      message: '',
      errors: {},
      successMessage: '',
    };
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  validateForm = () => {
    const { Name, EmailID, PhoneNumber } = this.state;
    const errors = {};
    if (!Name) {
      errors.Name = 'Name is required';
    }
    if (!EmailID) {
      errors.EmailID = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(EmailID)) {
      errors.EmailID = 'Invalid email format';
    }
    if (!PhoneNumber) {
      errors.PhoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(PhoneNumber)) {
      errors.PhoneNumber = 'Invalid phone number format (10 digits)';
    }
    return errors;
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validateForm();

    if (Object.keys(errors).length === 0) {
      // Validation passed, make the POST request
      const { Name,
        EmailID,
        PhoneNumber,
        Field1,
        Messages,
        BranchCode,
        AccessKey, } = this.state;
      const requestBody = {
        Name,
        EmailID,
        PhoneNumber,
        Field1,
        Messages,
        BranchCode,
        AccessKey,
      };
      try {
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/APIServices/PostEnquiry`;
        const userId = "thebcplgroup";
        const password = "http.com";
        const credentials = btoa(`${userId}:${password}`);
        const response = await fetch(
          apiUrl,
          {
            method: 'POST',
            headers: {
              Authorization: `Basic ${credentials}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          }
        );
        if (response.status === 200) {
          // Successful request
          this.setState({
            successMessage: 'Your message has been sent successfully!',

            errors: {},
            Name: '',
            EmailID: '',
            PhoneNumber: '',
            Field1: '',
            Messages: '',
          });
        } else {
          // Handle error responses here
          console.error('Error:', response.ErrorMessage);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      this.setState({ errors });
    }
  };
  render() {
    const { Name,
      EmailID,
      PhoneNumber,
      Field1,
      Messages,
      errors, successMessage } = this.state; 
    return (
      <>
        <div className='page-warp contactpage'>
          <section className="py-5 gradiant text-center">
            <h1 className='text-light'>Contact Us</h1>
            <p className='text-light mt-3'>Get in touch with us today, we’d love to hear from you.</p>
            
          </section>
          <section className="pt-5 pb-5 bg contact__us-hover contact__section">
            <div className="container">
              {/* <h2 className='mb-5'>Get in Touch</h2> */}
              <div className='row mb-4 blur_background '>
                <div className='col-md-3 mt-1'>
                  <div className='contact--icon__style'>
                  <h5 > <i class="fa-solid fa-location-dot"></i></h5>
                  </div>
                  <h5 className='mb-2 text-dark'>New Delhi</h5>
                  <p className=''> 811, 8th floor Eros Apartment -56,  <br/> Nehru Place ,New Delhi -110019  <br/> <span className='fw-bold'>E-mail : </span> <a href="mailto:sales@thebcplgroup.com">
                   sales@thebcplgroup.com
                  </a> <br/>  (Nehru Place-Corporate Office)
</p>
                </div>
                <div className='col-md-3 mt-1'>
                <div className='contact--icon__style'>
                  <h5 ><i class="fa-solid fa-location-dot"></i></h5>
                  </div>
                  <h5 className='mb-2 text-dark'>Uttar Pradesh</h5>
                  <p className=''>GF, 4/361, 104, Sec-4,<br/> Vaishali, Ghaziabad, <br/> Uttar Pradesh, 201010 <br/> (Vaishali Office-Head Office)  </p>
                </div>
                <div className='col-md-3 mt-1'>
                <div className='contact--icon__style'>
                  <h5 ><i class="fa-solid fa-location-dot"></i></h5>
                  </div>
                  <h5 className='mb-2 text-dark'>Gurugram</h5>
                  <p className=''>Plot No. 1111, GF,<br/> Opposite Omaxe Celebration Mall,<br/> SEC-47, Gurugram, Haryana <br/>(Gurugram-Branch Office) </p>
                </div>
                <div className='col-md-3 mt-1'>
                <div className='contact--icon__style'>
                  <h5 > <i class="fa-solid fa-phone"></i></h5>
                  </div>
                  <h5 className='mb-2 text-dark'>Call us at</h5>
                  <a href="tel:9818176405" >
                    <p className=''>+91 9818176405</p>
                  </a>
                  <a href="tel:9990906525" >
                    <p className=''>+91 9990906525 (Sales Enquiry)</p>
                  </a>
                  <a href="tel:+911141625550" >
                    <p className=''>+91 11 4162 5550 (15 Hunting lines)</p>
                  </a>
                  <a href="tel:+911204779918" >
                    <p className=''>+91 120 477 9918 (10 Hunting Lines)</p>
                  </a>
                </div>
                {/* <div className='col-md-3 mt-1'>
                <div className='contact--icon__style'>
                  <h5 > <i class="fa-solid fa-envelope"></i></h5>
                  </div>
                  <h5 className='mb-2 text-dark'>Email & Website</h5>
                  <a href="mailto:sales@thebcplgroup.com">
                    <p className=''>sales@thebcplgroup.com</p>
                  </a>
                  <a href="www.thebcplgroup.com" target="_blank">
                    <p>
                    www.thebcplgroup.com
                    </p>
                    </a>
                </div> */}
              </div>
              <div className="row justify-content-center blur_background mt-5">
              <div className='col-md-6 p-4'>
                <iframe className='rounded' src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3504.6871901228537!2d77.25162991147312!3d28.549120575609088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s302.%203rd%20Floor%2C%20Sheetla%20House%20No.73-74%2C%20Nehru%20Plac%2C%20New%20Delhi%20110019%20India!5e0!3m2!1sen!2sin!4v1693761803478!5m2!1sen!2sin" width="100%" height="550" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='col-md-6'>
                  <div className='contact-div  '>
                    {successMessage && <div className="success-message">{successMessage}</div>}
                    <form onSubmit={this.handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                          type="text"
                          name="Name"
                          value={Name}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                        {errors.Name && <div className="error">{errors.Name}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Email:</label>
                        <input
                          type="email"
                          name="EmailID"
                          className="form-control"
                          value={EmailID}
                          onChange={this.handleChange}
                        />
                        {errors.EmailID && <div className="error">{errors.EmailID}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Phone No:</label>
                        <input
                          className="form-control"
                          type="number"
                          maxLength="10"
                          name="PhoneNumber"
                          value={PhoneNumber}
                          onChange={this.handleChange}
                        />
                        {errors.PhoneNumber && <div className="error">{errors.PhoneNumber}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Subject:</label>
                        <input
                          className="form-control"
                          type="text"
                          name="Field1"
                          value={Field1}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Message:</label>
                        <textarea
                          name="Messages"
                          className="form-control"
                          value={Messages}
                          onChange={this.handleChange}
                        />
                        {errors.Messages && <div className="error">{errors.Messages}</div>}
                      </div>
                      <button type="submit" className=' btn btn-primary w-100'>Submit</button>
                    </form>
                  </div>
                </div>
               
                {/* <div className='col-md-6'>
                  <div className='row'>
                    <img src='assets/images/contact_us.png' className='d-none d-md-block' />
                  </div></div> */}
              </div>
            </div>
          </section >
          {/* <section >
            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3504.6871901228537!2d77.25162991147312!3d28.549120575609088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s302.%203rd%20Floor%2C%20Sheetla%20House%20No.73-74%2C%20Nehru%20Plac%2C%20New%20Delhi%20110019%20India!5e0!3m2!1sen!2sin!4v1693761803478!5m2!1sen!2sin" width="100%" height="550" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </section> */}
        </div>
      </>
    );
  }
}

export default Contact;
