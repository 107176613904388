import React, { useState, useEffect } from 'react';
function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [currentPage, setCurrentPage] = useState('home');
  const [data, setData] = useState(null);
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/APIServices/GetPortfolioData?BranchCode=Brinda&AccessKey=${process.env.REACT_APP_ACCESS_KEY}`;
  const userId = "thebcplgroup";
  const password = "http.com";
  const credentials = btoa(`${userId}:${password}`);
  useEffect(() => {
    fetch(apiUrl, {
      headers: {
        Authorization: `Basic ${credentials}`
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error("Fetch error:", error));
  }, []);
  return (
    <>
      <div className='page-warp contactpage'>
        <section className="py-5 gradiant text-center">
          <h1 className='text-light'>Portfolio</h1>
          <p className='text-light mt-3'>We are an established IT Infrastructure Services Provider specializing in Planning, Designing, Implementing and Maintaining IT Infrastructure.</p>
        </section>
        <section className="py-100">
         {data ? ( <div className="container">
                {data.map((item) => {
                  return (
                    <div className='bg service-card rounded-3 p-4 h-100 mb-4'>
                  <div className='row justify-content-between align-items-center g-5' >
                    <div className='col-md-5'>
                      <div className='services-img rounded-3'>
                        <img src={item.PhotoName} alt="Service-image-Error"/>
                      </div>
                    </div>
                     <div className='col-md-7'>
                        <h4 className='text-uppercase'>{item.ShortNotice}</h4>
                         <pre>{item.Descriptions}</pre>
                        {/* <Link className=" btn btn-primary mt-3" to="/Contact" onClick={() => handlePageChange('contact')}>Contact Us</Link> */}
                      </div>
                      </div>
                  </div>
                  )
                })}
              </div>
            ) : (
              <p>Loading data...</p>
            )}
        </section>

      </div>
    </>
  );
}
export default Contact;
