import React, { useState,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [data, setData] = useState(null);
  // const apiUrl = "https://webadmin.alexerp.com/api/APIServices/GetPortfolioData?BranchCode=Brinda&AccessKey=undbnTNU1qWFJQuJMXicynH6c";
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/APIServices/GetServicesData?BranchCode=Brinda&AccessKey=${process.env.REACT_APP_ACCESS_KEY}`;
  const userId = "thebcplgroup";
  const password = "http.com";
  const credentials = btoa(`${userId}:${password}`);
  useEffect(() => {
    fetch(apiUrl, {
      headers: {
        Authorization: `Basic ${credentials}`
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error("Fetch error:", error));
  }, []);
  return (
    <div className='page-warp contactpage'>
      <section className="py-5 gradiant text-center">
        <h1 className='text-light'>IT Solution & Services Capabilities</h1>
        <p className='text-light mt-3 text-capitalize'>
        Demand Managed IT Services And Solution All In One Location</p>
      </section>
      <section>
        <div className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-md-9  mt-5'>
<p className='text_jus'>
You are in charge of designing networks, infrastructure, software, applications, and other high-level tasks. If the company’s IT environment is running smoothly, then you’re on top of the world. If things aren’t going as planned, then you’re held accountable. <br/> <br/>
If the weight of the world sometimes seems like it’s resting on your shoulders, then BCPL can help ease the burden. BCPL understands the position that you’re in, the constraints you’re surrounded by, and the pressure you’re under better than any other company, and we’ve customized our solution sets in order to help you achieve your IT goals and implement the strategies that you and your team have painstakingly created.<br/> <br/>
BCPL’s comprehensive solutions sets, tools, and managed services act as an extension of the IT department you pride yourself on being a part of. We want to help in any way possible to ensure that  your IT budget stays on track, your applications and processes are running smoothly, and that you’re always connected to critical data whenever and wherever you need to be.<br/> <br/>
</p>
            </div>
          </div>

        </div>
       
      </section>
      <section className="py-100">
         {data ? ( <div className="container">
          <div className='row g-5'>
                {data.map((item) => {
                  return (
                    <div className='col-md-6'>
                       <div className='bg service-card rounded-3 p-4 h-100'>
                <div className='services-img rounded-3'>
                  <img src={item.PhotoName} alt="service-img" className=' ' />
                </div>
                <h4>{item.ShortNotice}</h4>
                <p className='text_jus'>{item.Descriptions} </p>
              </div>
                  </div>
                  )
                })}
              </div>
              </div>
            ) : (
              <p>Loading data...</p>
            )}
        </section>
      {/* <section className="py-100 ">
        <div className="container">
          <div className='row g-5'>
            <div className='col-md-6'>
              <div className='bg service-card rounded-3 p-4 h-100'>
                <div className='services-img rounded-3'>
                  <img src="assets/images/banner.png" alt="service-img" className=' ' />
                </div>
                <h4>Brinda IT Training Capabilities</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='bg service-card rounded-3 p-4 h-100'>
                <div className='services-img rounded-3'>
                  <img src="assets/images/banner.png" alt="service-img" className=' ' />
                </div>
                <h4>Brinda IT Training Capabilities</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
              </div>
            </div>

          </div>
        </div>
      </section > */}
    </div >
  );
}
export default Contact;