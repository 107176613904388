import React, { useState, useEffect } from 'react';
// import ClientsHome from './clientHome'
function Ewaste() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='page-warp contactpage'>
      <section className="py-5 gradiant text-center">
        <div className='container'>
          <h1 className='text-light'>E-waste</h1>
          <p className='text-light mt-3'>
            E-waste is a popular, informal name for electronic products nearing the end of their "useful life." Mobile Phones, Smart Phones, Feature phone, I Phones, Laptop, Desktop, Thin Clients, Air Conditioners, Televisions, Refrigerators, Washing Machines, VCDs, stereos, copiers, and fax machines are common electronic products. Many of these products can be reused, refurbished, or recycled.</p>
        </div>
      </section>
      <section className="py-2">
        <div className="container">
          <h2 data-aos="fade-up" className="mb-3">Introduction</h2>
          <p className="mb-3 text_jus">
            E-waste is a popular, informal name for electronic products nearing the end of their "useful life." Mobile Phones, Smart Phones, Feature phone, I Phones, Laptop, Desktop, Thin Clients, Air Conditioners, Televisions, Refrigerators, Washing Machines, VCDs, stereos, copiers, and fax machines are common electronic products. Many of these products can be reused, refurbished, or recycled. </p>
          <p className="mb-3 text_jus">
            E-waste has been one of the fastest growing waste streams in the world. While e-waste contains valuable materials such as aluminium, copper, gold, palladium and silver, it also contains harmful substances like cadmium, lead and mercury. In the absence of proper awareness, disposing e-waste in landfill can result in toxic emissions to the air, water and soil and pose a serious health and environmental hazards. </p>
          <p className="mb-3 text_jus">
            Under the guidelines of the Ministry of Environment, Forest and Climate Change, Government of India, E-Waste (Management) Rules, 2016 and BCPL stands committed to implement E-Waste Rules. </p>
          <p className="mb-3 text_jus">
            We, BCPL understand there is a need to encourage recycling of all useful and valuable material from e-waste so as to conserve the ever depleting natural resources. Recycling end-of-life discarded products is vital if we are to save resources and minimize landfillBCPL understands its responsibility and in this regards we have tied-up with one of the leading PROnamely SWACH ENVIRO INDIA E-WASTE PVT. LTD. for facilitating our customers to enable them to dispose of e-waste products after its end-of-life. </p>
          <p className=" text_jus">
            <span className='fw-bold'> Recycling of E-waste: </span>
            The company has tied -up with PRO M/S SWACH ENVIRO INDIA E-WASTE PVT. LTD. For collection of all our E-waste on PAN India and disposes the same with their Registered Recyclers Customer can reach or call on Toll Free No 18003093444. Our representatives explain them about process of disposal and make them aware about nearest drop point available to drop the e-waste also give information about incentive we will offer to them against their end of life product. If any customer wants to handover the material from their door steps, we do send either our logistics team or M/S SWACH ENVIRO INDIA E-WASTE PVT. LTD.  team to collect the items and channelize the same to our e-waste partner plant for final processing.
          </p>
        </div>
      </section>
      <section className="py-3">
        <div className="container">
          <div className="row align-items-center g-5">
            <div className="col-md-8">
              <h2 className=" mb-4" data-aos="fade-up" data-aos-duration="2000">Benefit of recycling of E-waste:</h2>
              <div className='listing ps-2'>
                <ul>
                  <li>Benefit of recycling of E-waste:
                    E-waste contains many valuable, recoverable materials such as aluminum, copper, gold, silver, plastics, and ferrous metals. In order to conserve natural resources and the energy needed to produce new electronic equipment from virgin resources, electronic equipment can be refurbished, reused, and recycled instead of being land filled.</li>
                  <li>E-waste also contains toxic and hazardous materials including mercury, lead, cadmium, beryllium, chromium, and chemical flame retardants, which have the potential to leach into our soil and water.</li>
                  <li>Protects your surroundings- Safe recycling of outdated electronics promotes sound management of toxic chemicals such as lead and mercury.</li>
                  <li>Conserves natural resources- Recycling recovers valuable materials from old electronics that can be used to make new products. As a result, we save energy, reduce pollution, reduce greenhouse gas emissions, and save resources by extracting fewer raw materials from the earth.</li>
                  <li>Helps others - Donating your used electronics benefits your community by passing on ready-to-use or refurbished equipment to those who need it.</li>
                  <li>Saves landfill space - E-waste is a growing waste stream. By recycling these items, landfill space is conserved.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <img src="assets/images/about/e-waste.jpg" />
            </div>
          </div>
        </div>
      </section>
      {/* <section >
        <div className='container'>
      <h2 className=" mb-4" data-aos="fade-up" data-aos-duration="2000">Our Clients</h2>
    <ClientsHome/>
    </div>
      </section> */}
    </div>
  );
}
export default Ewaste;