import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style.css'
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Inquery from "./quickInquery";
import CarouselFade from "./slider"
import ClientsHome from "./clientHome"

function Home() {

   const [data, setData] = useState(null);
   // const apiUrl = "https://webadmin.alexerp.com/api/APIServices/GetPortfolioData?BranchCode=Brinda&AccessKey=undbnTNU1qWFJQuJMXicynH6c";
   const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/APIServices/GetPartnersData?Type=Partners&BranchCode=Brinda&AccessKey=${process.env.REACT_APP_ACCESS_KEY}`;
   const userId = "thebcplgroup";
   const password = "http.com";
   const credentials = btoa(`${userId}:${password}`);
   useEffect(() => {
      fetch(apiUrl, {
         headers: {
            Authorization: `Basic ${credentials}`
         }
      })
         .then(response => response.json())
         .then(data => setData(data))
         // .then(data => setData.split(data,'\\r\\n'))

         .catch(error => console.error("Fetch error:", error));
   }, []);

   var settings = {
      dots: false,
      margin: 10,
      infinite: true,
      speed: 10000,
      slidesToShow: 5,
      slidesToScroll: 3,
      margin: 10,
      autoplay: true,
      arrows: false,
      cssEase: 'linear',
      speed: 7000,
      autoplaySpeed: 0,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         }
      ]
   };
   var settings2 = {
      dots: true,
      margin: 30,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      margin: 10,
      autoplay: true,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         }
      ]
   };
   return (
      <>
         <div className="page_warp">
            <section>
               <div className='container-fluid p-0 m-0'>
                  <div className='row'>
                     <CarouselFade />
                  </div>
               </div>
            </section>
            {/* <section className="banner__section">
               <div className="container">
                  <div className="row">
                     <div className="col-md-7">
                        <h1 data-aos="fade-up" >Welcome  to  <br />BCPL Technologies</h1>
                        <p data-aos="fade-up" className="banner__subtitle mt-3 text_jus">Brinda Corporation an ISO 9001:2000-certified company, is a
                           broad-based
                           information technology
                           solutions provider. It offers a comprehensive understanding of infrastructure solutions and
                           application deployment.</p>
                        <button data-aos="fade-up"  className="btn btn btn-primary mt-3 btn_shadow">Get Started</button>
                     </div>
                    
                  </div>
               </div>
            </section > */}
            <section className="py-100 pb-0">
               <div className="container">
                  <div className="row align-items-end g5">
                     <div className="col-md-12 col-lg-6 col-xl-7">
                        <div className="row">
                           <div className="col-md-6 mb-4">
                              <h5 className="mb-3" data-aos="fade-up">
                                 <img src="assets/images/icons/icon_1.png"
                                    alt="WirelessCampus" />
                                 Communications</h5>
                              <p data-aos="fade-up" className='text_jus'>BCPL offers telecommunications services or some combination of information and media services, content, entertainment and application services over networks, leveraging the network infrastructure as a rich, functional platform.</p>
                           </div>

                           <div className="col-md-6 mb-4">
                              <h5 data-aos="fade-up" className="mb-3">
                                 <img src="assets/images/icons/icon_1.png"
                                    alt="WirelessCampus" />
                                 SECURITY & Compliances</h5>
                              <p data-aos="fade-up" className='text_jus'>we work with our clients to put together comprehensive security solutions to fit their business needs. Observing regulatory security and compliance policies are vital for every organization.</p>
                           </div>

                           <div className="col-md-6 mb-4">
                              <h5 data-aos="fade-up" className="mb-3">
                                 <img src="assets/images/icons/icon_3.png"
                                    alt="WirelessCampus" />
                                 VIRTUALIZATION</h5>
                              <p data-aos="fade-up" className='text_jus'>In Virtualization process of creating a virtual version of a server or computer system using software rather than hardware. It enables multiple operating systems to run simultaneously on a single machine.</p>
                           </div>
                           <div className="col-md-6 mb-4">
                              <h5 data-aos="fade-up" className="mb-3">
                                 <img src="assets/images/icons/icon_2.png"
                                    alt="WirelessCampus" />
                                 SERVER STORAGE AND BACKUP</h5>
                              <p data-aos="fade-up" className='text_jus'>BCPL offers the best solutions for your Server and Storage. We offer custom pre-made and after sales in various areas, such as virtualization, storage, connectivity and servers.</p>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-12 col-lg-6 col-xl-5 ">
                        <img src="assets/images/pages/home/section_1.png" alt="Wireless Campus" className="w-100"
                           data-aos="fade-left" />
                     </div>
                  </div>
               </div>
            </section>
            <section className="py-100 pb-0">
               <div className="container">
                  <div className="row align-items-center g-5 flex-row-reverse">
                     <div className="col-md-12 col-lg-6 col-xl-7 ">
                        <h2 className="mb-3" data-aos="fade-up">Our Value Proposition </h2>
                        <p data-aos="fade-up" className='text_jus'>In today’s world Companies buy technology for competitive advantage to achieve parity with others. Our world is full of new applications, technologies, processes, methods and resources created by enlightened people and companies. Getting all the pieces to work together as one coherent solution is not an easy task. So in simple terms, our value proposition is to "Pull it all together". This could mean by putting together any
                           combination of hardware, software, firmware and advice and making sure that the assembly works to a client's satisfaction. </p>

                     </div>
                     <div className="col-md-12 col-lg-6 col-xl-5 ">
                        <img src="assets/images/pages/home/value.svg" data-aos="fade-right" alt="WirelessCampus"
                           className="w-100" />
                     </div>
                  </div>
               </div>
            </section>
            <section className="py-100 ">
               <div className="container">
                  <div className="row align-items-center g-5">
                     <div className="col-md-12 col-lg-6 col-xl-7 ">
                        <h2 data-aos="fade-up" className="mb-3">Our Integrated Quality System </h2>
                        <p data-aos="fade-up" className='text_jus'>Brinda has continuously thrived to achieve high quality standard and subject
                           ourselves to most stringent quality system practices in industry. Brinda has not
                           only lived up to benchmarks created by the industry but has surpassed them in
                           customer satisfaction and organizational performance. Brinda has achieved
                           integrated quality certification ISO 9001:2000. The adherence to this standard
                           raises the internal quality bar. </p>
                     </div>
                     <div className="col-md-12 col-lg-6 col-xl-5">
                        <img src="assets/images/pages/home/boat.svg" alt="WirelessCampus" data-aos="fade-left"
                           className="w-100" />
                     </div>
                  </div>
               </div>
            </section>
            {/* <section className="py-100 our_company">
   <div className="container">
      <div className="row align-items-center text-center justify-content-center g-5">
         <div className="col-md-12 col-lg-7 col-xl-7 ">
            <h2 data-aos="fade-up" className="mb-3 text-white">Our Company</h2>
            <p data-aos="fade-up" className="text-white">Brinda Corporation an ISO 9001:2000 certified company, is broad-based information technology solutions provider. It offers a comprehensive understanding of IT infrastructure solutions and application deployment. It can manage virtually any IT
environment from legacy systems to futuristic sophisticated distributed service topologies. The comprehensive range of services offered by Brinda allows the client to select the specific capabilities that best need their business needs. <br><br>

Brinda does this through innovation and efficient execution - steeled by the principle of consistency. Brinda believes that there are some core attributes that underpin success – such as: Being driven by market need, being innovative, staying focused, being true to best processes, hiring and retaining outstanding people, being fair and ethical at all times, Assessing its progress through the satisfaction of its clients, through teamwork and goal congruence at all levels. <br><br>


Incorporated in 2020, Brinda Corporation has strong technical background, sales expertise and promises to deliver to the satisfaction of the customer. Since its inception, Brinda Corporation has been growing consistently and has earned respect for its deliverance. <br><br>

</p>
            <button data-aos="fade-up" className="btn btn btn-primary mt-5">Read More</button>

         </div>
      </div>
   </div>
</section> */}
            <section className=" bg">
               <div className="container">
                  <div className="row align-items-center justify-content-between text-center g-5">
                     <div className="col-12 col-md-12 col-lg-6 col-xl-4  py-100 mt-0 ">
                        <h2 data-aos="fade-up" className="mb-3">Our Story </h2>
                        <p data-aos="fade-up" className=''>Brinda is committed to provide best quality Sales & Services, to match the requirements of customer satisfaction. Our goal is to exceed customer expectations
                           through customer focus and continual improvement by effective implementation of quality management System.
                        </p>
                     </div>
                     <div className="col-md-12 col-lg-6 col-xl-7 text-center py-100 mt-0  position-relative">
                        <div className='gradinat-img'></div>
                        <img src="assets/images/pages/home/our-story.png" alt="WirelessCampus" data-aos="fade-left"
                           className="w-50 z-3 position-relative" />
                     </div>
                  </div>
               </div>
            </section>
            <section className="py-100 pb-0">
               <div className="container">
                  <div className="row align-items-center justify-content-between text-center g-5">
                     <div className="col-md-12 col-lg-6 col-xl-5 ">
                        <h2 data-aos="fade-up" className="mb-3">Our Vision</h2>
                        <p data-aos="fade-up" className='text_jus'>As a high-touch IT solutions provider, BCPL is focused on the movement, management, and protection of data. BCPL offers many services and strategic IT solutions to help you Evolve to the Cloud™, backup and restore your data after a disaster, modernize your data center or IT infrastructure, and much more.

                        </p>

                     </div>
                     <div className="col-md-12 col-lg-6 col-xl-4 ">
                        <img src="assets/images/pages/home/vision.svg" alt="WirelessCampus" data-aos="fade-left"
                           className="w-100" />
                     </div>
                  </div>
               </div>
            </section>
            <section className="py-100 pb-0">
               <div className="container">
                  <div className="row align-items-center justify-content-between text-center g-5 flex-row-reverse">
                     <div className="col-md-12 col-lg-6 col-xl-5 ">
                        <h2 data-aos="fade-up" className="mb-3">Our Mission</h2>
                        <p data-aos="fade-up" className='text_jus'>
                           We will strive to attain a leadership position in the market, we chose to serve by creative innovative and differentiated IT and IT enabled solutions that are delivered with impeccable quality. We believe that this will enable us to add tangible value to the business of our clients, in every engagement.
                        </p>

                     </div>
                     <div className="col-md-12 col-lg-6 col-xl-4 ">
                        <img src="assets/images/pages/home/mission.jpg" alt="WirelessCampus" data-aos="fade-right"
                           className="w-100" />
                     </div>
                  </div>
               </div>
            </section>
            <section className="py-100 pb-0 mb-4">
               <div className="container">
                  <div className="row align-items-center justify-content-between text-center g-5">
                     <div className="col-md-12 col-lg-6 col-xl-5 order-1">
                        <h2 data-aos="fade-up" className="mb-3">Our Support</h2>
                        <p data-aos="fade-up" className='text_jus'>Solution-driven Support Engineers with solid knowledge in networks, systems, and hardware. Provides installation, maintenance, troubleshooting, administrative support, and network user management in a multi-site environment. Integrates technical acumen with problem-solving expertise to expand IT operations. Leverages technical support skills with extensive teaching experience, training teams in effective problem resolution and service methods to improve customer satisfaction.
                        </p>

                     </div>
                     <div className="col-md-12 col-lg-6 col-xl-7 order-2">
                        <div className="row g-5">
                           <div className="col-md-6 pt-80">
                              <div className="support_card mb-5" data-aos="fade-up">
                                 <img src="assets/images/icons/icon_5.png" className="mb-4" data-aos="fade-up"
                                    alt="Network Design" />
                                 <h6 className="mb-3" data-aos="fade-up">Cloud Services</h6>
                                 <p data-aos="fade-up" className='text_jus'>Cloud-based technologies gained large popularity among organizations due to the flexibility and comfort of operation they bring data storage, office applications, documents, and other techniques are used almost in every business.

                                 </p>
                              </div>
                              <div className="support_card" data-aos="fade-up" data-aos-delay="300">
                                 <img src="assets/images/icons/icon_8.png" className="mb-4" data-aos="fade-up"
                                    alt="Network Design" />
                                 <h6 className="mb-3" data-aos="fade-up">Structured Cabling </h6>
                                 <p data-aos="fade-up" className='text_jus'>Dedicated specialists provide elegant structured cabling solutions so that your company can take advantage of modern technology in a secure and cost-effective manner.</p>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="support_card mb-5" data-aos="fade-up" data-aos-delay="400">
                                 <img src="assets/images/icons/icon_7.png" className="mb-4" data-aos="fade-up"
                                    alt="Network Design" />
                                 <h6 className="mb-3" data-aos="fade-up">Network Service & Solutions</h6>
                                 <p data-aos="fade-up" className='text_jus'>Every computer owner uses networks, which have been installed to that very end and every now and then require some minor network troubleshooting.

                                 </p>
                              </div>
                              <div className="support_card" data-aos="fade-up" data-aos-delay="500">
                                 <img src="assets/images/icons/icon_6.png" className="mb-4" data-aos="fade-up"
                                    alt="Network Design" />
                                 <h6 className="mb-3" data-aos="fade-up">Server Room Setup</h6>
                                 <p data-aos="fade-up" className='text_jus'>Specialists have solid expertise in server room design and ready to assist your company in obtaining a secure, reliable, and high-performance server room setup.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <section className="mb-2 ">
               <div className='container '>
                  <div className='row flex-wrap'>
                     <div className='col-md-12 col-lg-3 p-5 d-flex align-items-center bg' 
                     // style={{backgroundColor:'white'}}
                     >
                        <div>
                     <h2 data-aos="fade-up" className="mb-3 ">Our Verticals</h2>
                        <p data-aos="fade-up" className='text_jus'>Our expertise spans across these diverse verticals, enabling us to deliver tailored solutions that drive success, efficiency, and innovation. Contact us to explore how our solutions can transform your specific industry.
                        </p>
                        </div>
                     </div>
                     <div className='col-lg-9 col-md-12 d-flex flex-wrap'>
                        <div className='col-md-4 py-4  p-2'>
                           <div className='col-md-12 mb-2'>
                              <div className='verticle_icon'>
                              <img src="assets/images/pages/home/parkings.svg" className="" data-aos="fade-up"
                                    alt="Network Design" />
                              </div>
                           
                           </div>
                           <div className='col-md-12'>
                           <h6 className="mb-3 fw-bold text-dark" data-aos="fade-up">Parking</h6>
                                 <p data-aos="fade-up" className='text_jus'>Efficiently managing parking spaces is crucial for urban planning and businesses. Our innovative solutions optimize parking resources, enhance user experience, and reduce congestion.

                                 </p>
                           </div>

                        </div>
                        <div className='col-md-4 py-4 p-2'>
                           <div className='col-md-12'>
                           <div className='col-md-12 mb-2'>

                              <div className='verticle_icon'>
                              <img src="assets/images/pages/home/heathcare.svg" className="" data-aos="fade-up"
                                    alt="Network Design" />
                              </div>
                           
                           </div>
                           </div>
                           <div className='col-md-12'>
                           <h6 className="mb-3 fw-bold text-dark" data-aos="fade-up">Healthcare</h6>
                                 <p data-aos="fade-up" className='text_jus'>In the healthcare sector, precision and reliability are paramount. We provide cutting-edge technology solutions that enhance patient care, streamline operations, and improve overall efficiency.

                                 </p>
                           </div>

                        </div>
                        <div className='col-md-4 py-4 p-2'>
                           <div className='col-md-12'>
                           <div className='col-md-12 mb-2'>
                              <div className='verticle_icon'>
                              <img src="assets/images/pages/home/hospital.svg" className="" data-aos="fade-up"
                                    alt="Network Design" />
                              </div>
                           
                           </div>
                           </div>
                           <div className='col-md-12'>
                           <h6 className="mb-3 fw-bold text-dark" data-aos="fade-up">Government</h6>
                                 <p data-aos="fade-up" className='text_jus'>Government agencies require robust systems for data management and public services. Our solutions empower government bodies with tools for transparency, efficiency, and citizen engagement.

                                 </p>
                           </div>

                        </div>
                        <div className='col-md-4 py-4 p-2'>
                           <div className='col-md-12'>
                           <div className='col-md-12 mb-2'>
                              <div className='verticle_icon'>
                              <img src="assets/images/pages/home/education.svg" className="" data-aos="fade-up"
                                    alt="Network Design" />
                              </div>
                           
                           </div>
                           </div>
                           <div className='col-md-12'>
                           <h6 className="mb-3 fw-bold text-dark" data-aos="fade-up">Education</h6>
                                 <p data-aos="fade-up" className='text_jus'>Education is the cornerstone of progress. We offer technology solutions that facilitate interactive learning, administrative efficiency, and student engagement in academic institutions.

                                 </p>
                           </div>

                        </div>
                        <div className='col-md-4 py-4 p-2'>
                           <div className='col-md-12'>
                           <div className='col-md-12 mb-2'>
                              <div className='verticle_icon'>
                              <img src="assets/images/pages/home/realstate.svg" className="" data-aos="fade-up"
                                    alt="Network Design" />
                              </div>
                           
                           </div>
                           </div>
                           <div className='col-md-12'>
                           <h6 className="mb-3 fw-bold text-dark" data-aos="fade-up">Corporates</h6>
                                 <p data-aos="fade-up" className='text_jus'>Corporate environments demand secure and scalable IT infrastructure. Our solutions cater to the unique needs of businesses, boosting productivity and ensuring data integrity.

                                 </p>
                           </div>

                        </div>
                        <div className='col-md-4 py-4 p-2'>
                           <div className='col-md-12'>
                           <div className='col-md-12 mb-2'>
                              <div className='verticle_icon'>
                              <img src="assets/images/pages/home/heathcare.svg" className="" data-aos="fade-up"
                                    alt="Network Design" />
                              </div>
                           
                           </div>
                           </div>
                           <div className='col-md-12'>
                           <h6 className="mb-3 fw-bold text-dark" data-aos="fade-up">Hospitality</h6>
                                 <p data-aos="fade-up" className='text_jus'>In the hospitality industry, guest satisfaction is the ultimate goal. Our technology solutions elevate guest experiences, streamline operations, and increase profitability.

                                 </p>
                           </div>

                        </div>
                       
                     </div>
                  </div>
               </div>
               </section>    
            <section className="py-100 pb-0">
               <div className="container">
                  <div className="row align-items-center justify-content-center text-center g-5 mb-2">
                     <div className="col-md-12 col-lg-6 col-xl-5 order-1">
                        <h2 data-aos="fade-up" className="mb-3">Our Partners</h2>
                        <p data-aos="fade-up">Our partners programme can be divided into four categories. They are:

                           Business Partners, Technology Partners, Solutions Partners, Consulting Partners.
                        </p>
                     </div>
                  </div>
                  {data ? (<Slider {...settings}>
                     {data.map((item) => {
                        return (
                           <div className="item">
                              <div className="team_div " data-aos="fade-up">
                                 <img src={item.PhotoName} alt={item.Descriptions} />
                                 {/* <h6 className="mb-2">{item.Descriptions}</h6> */}
                              </div>
                           </div>
                        )
                     })}
                  </Slider>
                  ) : (
                     <p>Loading data...</p>
                  )}
               </div>
            </section>
            <section className="py-100 pb-0">
               <div className="container">
                  <div className="row align-items-center justify-content-center text-center g-5 mb-5">
                     <div className="col-md-12 col-lg-6 col-xl-5 order-1">
                        <h2 data-aos="fade-up" className="mb-3">Our Clients</h2>
                        <p data-aos="fade-up">Clients Served with Our Managed IT Services
                        </p>
                     </div>
                  </div>
                  <ClientsHome />
               </div>
            </section>

            {/* <section className="py-100 pb-0">
               <div className="container">
                  <div className="row align-items-center justify-content-center text-center g-5 mb-5">
                     <div className="col-md-12 col-lg-6 col-xl-5 order-1">
                        <h2 data-aos="fade-up" className="mb-3">Our Clients</h2>
                        <p data-aos="fade-up">Clients Served with Our Managed IT Services
                        </p>
                     </div>
                  </div>
                  <Slider {...settings}>
                     <div className="item">
                        <div className="team_div " data-aos="fade-up">
                           <img src="assets/images/team/hcl.png" alt='HCL Technologies' />
                           <h6 className="mb-2">HCL Technologies</h6>
                           
                        </div>
                     </div>
                     <div className="item">
                        <div className="team_div" data-aos="fade-up" data-aos-delay="300">
                           <img src="assets/images/team/l&t.png" />
                           <h6 className="mb-2">Larsen & Toubro</h6>
                          
                        </div>
                     </div>
                     <div className="item">
                        <div className="team_div" data-aos="fade-up" data-aos-delay="400">
                           <img src="assets/images/team/dps_school.png" />
                           <h6 className="mb-2">DPS Schools</h6>
                          
                        </div>
                     </div>
                     <div className="item">
                        <div className="team_div" data-aos="fade-up" data-aos-delay="500">
                           <img src="assets/images/team/kei.png" />
                           <h6 className="mb-2">KEI Industries</h6>
                          
                        </div>
                     </div>
                     <div className="item">
                        <div className="team_div" data-aos="fade-up" data-aos-delay="600">
                           <img src="assets/images/team/niu.png" />
                           <h6 className="mb-2">Noida International University</h6>
                        
                        </div>
                     </div>
                  </Slider>



               </div>
            </section> */}

            <section className="py-100 ">
               <div className="container">
                  <div className="row align-items-center justify-content-center text-center g-5 mb-5">
                     <div className="col-md-12 col-lg-6 col-xl-5 order-1">
                        <h2 data-aos="fade-up" className="mb-3">Testmonial</h2>
                        {/* <p data-aos="fade-up" className='text_jus'>Our team is composed of highly talented professionals who are experts in
                           building real-world
                           decentralized platforms to support the people globally.
                        </p> */}
                     </div>
                  </div>
                  <Slider {...settings2}>
                     <div className="item">
                        <div className="testmonial-div bg"  >
                           <img src="assets/quot.png" alt="qout" className='qout' />
                           <p className='mb-3 text_jus' >BCPL Technologies has been an invaluable partner for our IT needs. Their expertise in infrastructure solutions and application deployment is evident. Highly recommended!
                           </p>
                           <div className='d-flex align-items-center'>
                              <img src="assets/images/team/team_5.png" alt="client-img" className='client-img d-none d-sm-block me-3' />
                              <div>
                                 <h5 className=''>Pradeep  <br />
                                    <span className='fs-6'>  Atles Shipping & Services Pvt Ltd</span>
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="item">
                        <div className="testmonial-div bg"  >
                           <img src="assets/quot.png" alt="qout" className='qout' />
                           <p className='mb-3 text_jus'>We are thrilled with BCPL Technologies assures quality. Their efficient infrastructure solutions and application deployment optimized our processes.

                           </p>
                           <div className='d-flex align-items-center'>
                              <img src="assets/images/team/team_5.png" alt="client-img" className='client-img d-none d-sm-block me-3' />
                              <div>
                                 <h5 className=''>Bhanu Pratap    <br />
                                    <span className='fs-6'>  Burman Hospitality</span>
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="item">
                        <div className="testmonial-div bg"  >
                           <img src="assets/quot.png" alt="qout" className='qout' />
                           <p className='mb-3 text_jus'>BCPL Technologies’ commitment to excellence, has been instrumental in our growth. Their expertise in infrastructure solutions and application deployment is invaluable.</p>
                           <div className='d-flex align-items-center'>
                              <img src="assets/images/team/team_5.png" alt="client-img" className='client-img d-none d-sm-block me-3' />
                              <div>
                                 <h5 className=''>Alok K Srivastav  <br />
                                    <span className='fs-6'>  Varindera Constructions Ltd</span>
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="item">
                        <div className="testmonial-div bg"  >
                           <img src="assets/quot.png" alt="qout" className='qout' />
                           <p className='mb-3 text_jus'>BCPL Technologies makes them a reliable IT partner. Their expertise in infrastructure solutions and application deployment enhances our operations.
                           </p>
                           <div className='d-flex align-items-center'>
                              <img src="assets/images/team/team_5.png" alt="client-img" className='client-img d-none d-sm-block me-3' />
                              <div>
                                 <h5 className=''>Kazim  <br />
                                    <span className='fs-6'> Noida International University</span>
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="item">
                        <div className="testmonial-div bg"  >
                           <img src="assets/quot.png" alt="qout" className='qout' />
                           <p className='mb-3 text_jus'>BCPL Technologies consistently delivers exceptional IT solutions. Their commitment to quality. We highly recommend them for top-notch IT services</p>
                           <div className='d-flex align-items-center'>
                              <img src="assets/images/team/team_5.png" alt="client-img" className='client-img d-none d-sm-block me-3' />
                              <div>
                                 <h5 className=''>Yogesh Kumar<br />
                                    <span className='fs-6'>HCL Technologies  </span>
                                 </h5>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Slider>
               </div>
            </section>
            <section>
               < Inquery />
            </section>
         </div >
      </>
   );
}
export default Home;

