import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Footer from './footer';
import './App.css';
import Home from './home';
import About from './About';
import Contact from './Contact';
import Services from './Services';
import Portfolio from './Portfolio';
import Partners from './Partners';
import Clients from './Clients';
import Privacy from './Privacy';
import Terms from './Terms';
import Ewaste from './Ewaste';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Row } from 'react-bootstrap';
import Marquee from "react-fast-marquee";
import './Style.css';
// import logo from 'images/logo.png'

function App() {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])
   const [currentPage, setCurrentPage] = useState('home'); // Set the initial active page
   // Function to update the active page
   const handlePageChange = (page) => {
      setCurrentPage(page);
   };
   const pdfFileName = 'BCPL-Technologies.pdf';
   return (
      <>
         <Router>
            <header className='sticky m-0 p-0 d-flex justify-content-center align-items-center'>
               <div className="container-fluid bg-light">
                  <Row >
                  <Marquee pauseOnHover>
                     <div className='d-flex gap-3 mt-3 justify-content-between'>
                        <div> <p className='header_font_size'> For Inquiry :
                        <a href="tel:9990906525"> 9990906525</a></p> </div>
                        <div className='ms-3 me-2'><p className='header_font_size'> For Sale :  
                        <a href="tel:0120-4779918"> 012-04779918</a>, 
                        <a className='ms-2 me-2' href="tel:01141625550"> 011-41625550</a>
                        </p></div>
                     </div> 
                     </Marquee>
                     {['xl'].map((expand) => (
                        <Navbar key={expand} expand={expand} className="" collapseOnSelect>
                           <Container fluid>
                              <Row className='flex-wrap justify-content-between'>
                                 <div className='col-3 col-md-2 d-flex align-items-center'>
                                    <Navbar.Brand >
                                       <Link className="" to="/">
                                          <img alt="LOGO" src="assets/images/logo.png" className="w-50 logo-white" />
                                          <img alt="LOGO" src="assets/images/BCPL_tag.png" className="logo-size logo-colord" />
                                          {/* <img alt="LOGO" src="assets/images/logo-color.png" className="w-50 logo-colord" /> */}
                                       </Link>
                                    </Navbar.Brand>
                                 </div>
                                 <div className='col-auto d-flex flex-end align-items-center'>
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                                 </div>
                                 <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="end"
                                 >
                                    <Offcanvas.Header closeButton>
                                       <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                          BCPL
                                       </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                       <Nav className="justify-content-end flex-grow-1 pe-3" collapseOnSelect>
                                          {/* <Nav.Link href="#action1">Home</Nav.Link> */}
                                          <Nav.Link href="#about" >
                                             <Link className="nav-link" to="/About" onClick={() => handlePageChange('about')} >About Us</Link>
                                          </Nav.Link>
                                          <Nav.Link href="#Services">
                                             <Link className="nav-link" to="/Services" onClick={() => handlePageChange('services')} >Services</Link>
                                          </Nav.Link>
                                          <Nav.Link href="#Portfolio">
                                             <Link className="nav-link" to="/Portfolio" onClick={() => handlePageChange('Portfolio')} >Portfolio</Link>
                                          </Nav.Link>
                                          <Nav.Link href="#Clients">
                                             <Link className="nav-link" to="/Clients" onClick={() => handlePageChange('Clients')} >Clients</Link>
                                          </Nav.Link>
                                          <a href="assets/images/BCPL-Technologies.pdf" download={pdfFileName} className="nav-link mt-2">
                                             Brochure
                                          </a>
                                          <Nav.Link href="#Partners">
                                             <Link className="nav-link" to="/Partners" onClick={() => handlePageChange('Partners')} >Partners</Link>
                                          </Nav.Link>
                                          <Nav.Link href="#Ewaste">
                                             <Link className="nav-link" to="/Ewaste" onClick={() => handlePageChange('Ewaste')} >E-Waste</Link>
                                          </Nav.Link>
                                          <Nav.Link href="#action7">
                                             <Link className=" btn btn-primary" to="/Contact" onClick={() => handlePageChange('contact')}>Contact Us</Link>
                                          </Nav.Link>
                                       </Nav>
                                    </Offcanvas.Body>
                                 </Navbar.Offcanvas>
                              </Row>
                           </Container>
                        </Navbar>
                     ))}
                  </Row>
               </div>
            </header>
            <Routes >
               <Route exact path='/' element={< Home />}></Route>
               <Route exact path='/About' element={< About />}></Route>
               <Route exact path='/contact' element={< Contact />}></Route>
               <Route exact path='/services' element={< Services />}></Route>
               <Route exact path='/portfolio' element={< Portfolio />}></Route>
               <Route exact path='/Clients' element={< Clients />}></Route>
               <Route exact path='/Privacy' element={< Privacy />}></Route>
               <Route exact path='/Partners' element={< Partners />}></Route>
               <Route exact path='/Ewaste' element={< Ewaste />}></Route>
               <Route exact path='/Terms' element={< Terms />}></Route>
            </Routes>
            <Footer />
         </Router>
      </>
   );
}
export default App;
