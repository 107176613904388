import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ClientsHome() {
  const [data, setData] = useState(null);
  // const apiUrl = "https://webadmin.alexerp.com/api/APIServices/GetPortfolioData?BranchCode=Brinda&AccessKey=undbnTNU1qWFJQuJMXicynH6c";
  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/APIServices/GetPartnersData?Type=Customer&BranchCode=Brinda&AccessKey=${process.env.REACT_APP_ACCESS_KEY}`;
  const userId = "thebcplgroup";
  const password = "http.com";
  const credentials = btoa(`${userId}:${password}`);
  useEffect(() => {
    fetch(apiUrl, {
      headers: {
        Authorization: `Basic ${credentials}`
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error("Fetch error:", error));
  }, []);

  var settings4 = {
    dots: false,
    margin: 30,
    infinite: true,
    speed: 7000,
    slidesToShow: 5,
    slidesToScroll: 3,
    autoplaySpeed: 0,
    arrows: false,
    margin: 10,
    autoplay: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      {data ? (<Slider {...settings4}>
        {data.map((item) => {
          return (
            <div className="item">
              <div className="client_div" data-aos="fade-up">
                <img src={item.PhotoName} alt={item.Descriptions} className='circle' />
                {/* <h6 className="mb-2">{item.Descriptions}</h6> */}
              </div>
            </div>
          )
        })}
      </Slider>
      ) : (
        <p>Loading data...</p>
      )}
    </>

  );

}
export default ClientsHome;