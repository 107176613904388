import React, { Component, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class Inquery extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            EmailID: '',
            PhoneNumber: '',
            BranchCode: 'Brinda',
            AccessKey: process.env.REACT_APP_ACCESS_KEY,
            message: '',
            errors: {},
            successMessage: '',
        };
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    validateForm = () => {
        const { Name, EmailID, PhoneNumber } = this.state;
        const errors = {};
        if (!Name) {
            errors.Name = 'Name is required';
        }
        if (!EmailID) {
            errors.EmailID = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(EmailID)) {
            errors.EmailID = 'Invalid email format';
        }

        if (!PhoneNumber) {
          errors.PhoneNumber = 'Phone number is required';
        } else if (!/^\d{10}$/.test(PhoneNumber)) {
          errors.PhoneNumber = 'Invalid phone number format (10 digits)';
        }

        return errors;
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const errors = this.validateForm();

        if (Object.keys(errors).length === 0) {
            // Validation passed, make the POST request
            const { Name,
                EmailID,
                PhoneNumber,
                Field1,
                Messages,
                BranchCode,
                AccessKey, } = this.state;
            const requestBody = {

                Name,
                EmailID,
                PhoneNumber,
                Field1,
                Messages,
                BranchCode,
                AccessKey,
            };

            try {
                
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/APIServices/PostEnquiry`;
                const userId = "thebcplgroup";
                const password = "http.com";
                const credentials = btoa(`${userId}:${password}`);
                const response = await fetch(
                    apiUrl,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Basic ${credentials}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestBody),
                    }
                );

                if (response.status === 200) {
                    // Successful request
                    this.setState({
                        successMessage: 'Your message has been sent successfully!',

                        errors: {},
                        Name: '',
                        EmailID: '',
                        PhoneNumber: '',
                        Field1: '',
                        Messages: '',

                    });
                } else {
                    // Handle error responses here
                    console.error('Error:', response.ErrorMessage);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            this.setState({ errors });
        }
    };

    

    render() {
        const { Name,
            EmailID,
            PhoneNumber,
            Field1,
            Messages,
            errors, successMessage } = this.state;
        return (
            <>
        <div className='page-warp contactpage inquery'>
        <div className="row align-items-center justify-content-center text-center g-5 mb-3">
                     <div className="col-md-12 col-lg-6 col-xl-5 order-1">
                        <h2 data-aos="fade-up " className="mb-3 text-light">Enquiry</h2>
                        {/* <p data-aos="fade-up" className='text_jus'>Our team is composed of highly talented professionals who are experts in
                           building real-world
                           decentralized platforms to support the people globally.
                        </p> */}
                     </div>
                  </div>
      <section className="">
        <div className="container">
          <div className="row ">
            <div className='col-md-12 d-flex justify-content-center'>
            <div className='contact-div d-flex justify-content-center'>
                {successMessage && 
                alert(successMessage)
                // <div className="success-message">{successMessage}</div>
                }
                <form onSubmit={this.handleSubmit} className='d-flex flex-wrap gap-2 justify-content-center'>
                    <div className="mb-3 col-md-5 col-12">
                        <label className="form-label">Name:</label>
                        <input
                            type="text"
                            name="Name"
                            value={Name}
                            onChange={this.handleChange}
                            className="form-control"
                        />
                        {errors.Name && <div className="error">{errors.Name}</div>}
                    </div>
                    <div className="mb-3 col-md-5 col-12">
                        <label className="form-label">Email:</label>
                        <input
                            type="email"
                            name="EmailID"
                            className="form-control"
                            value={EmailID}
                            onChange={this.handleChange}
                        />
                        {errors.EmailID && <div className="error">{errors.EmailID}</div>}
                    </div>
                    <div className="mb-3 col-md-5 col-12">
                    <label className="form-label">Phone No:</label>
                        <input
                       className="form-control"
                            type="number"
                            maxLength="10"
                            name="PhoneNumber"
                            value={PhoneNumber}
                            onChange={this.handleChange}
                        />
                         {errors.PhoneNumber && <div className="error">{errors.PhoneNumber}</div>}
                    </div>
                    <div className="mb-3 col-md-5 col-12">
                    <label className="form-label">Subject:</label>
                        <input
                       className="form-control"
                            type="text"
                            name="Field1"
                            value={Field1}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="mb-3 col-md-10 col-12">
                        <label className="form-label">Message:</label>
                        <textarea
                            name="Messages"
                            className="form-control"
                            value={Messages}
                            onChange={this.handleChange}
                        />
                        {errors.Messages && <div className="error">{errors.Messages}</div>}
                    </div>
                    <div className="mb-3 col-md-10">
                    <button type="submit" className=' btn btn-primary '>Submit</button>
                        </div>
                    
                </form>
            </div>
            </div>
          </div>
        </div>
      </section >
   
    </div>

            </>
        );
    }
}

export default Inquery;
