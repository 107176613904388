import React, { Component, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Privacy from './Privacy'
import WhatsAppButton from './WhatsAppButton';
import FBButton from './fbButton'
function Footer() {
   const [currentPage, setCurrentPage] = useState('home'); 
   const handlePageChange = (page) => {
      setCurrentPage(page);
   };
  return (
<>
<footer>
   <div className="container">
      <div className="row pt-5">
         <div className="col-md-4 col-12">
            <img src="assets/images/BCPL_tag.png" width="200px" className="mb-3" />
            <p className="mb-3 text_jus">Brinda Corporation an ISO 9001:2000-certified company, is a broad-based information technology solutions provider. It offers a comprehensive understanding of infrastructure solutions and application deployment.</p>
         
         </div>
         <div className="col-md-1"></div>
         <div className="col-md-7 ">
            <div className="row">
               <div className="col-md-4 col-12 mb-2">
                  <h6 className="mb-3">Quick links</h6>
                  <ul>
                     <li><Link className="nav-link" to="/About" onClick={() => handlePageChange('about')} >About Us</Link> </li>
                     <li><Link className="nav-link" to="/Services" onClick={() => handlePageChange('services')} >Services</Link></li>
                     <li><Link className="nav-link" to="/Clients" onClick={() => handlePageChange('Clients')} >Clients</Link></li>
                     <li><Link className=" nav-link" to="/Contact" onClick={() => handlePageChange('contact')}>Contact Us</Link></li>
                     
                  </ul>
               </div>
               <div className="col-md-4 col-12 mb-2">
                  <h6 className="mb-3">Services</h6>
                  <ul>
                     <li> <Link className="nav-link" to="/Services" onClick={() => handlePageChange('services')} >Wireless Campus</Link></li>
                     <li> <Link className="nav-link" to="/Services" onClick={() => handlePageChange('services')} >Smart Card Based Campus</Link></li>
                     <li> <Link className="nav-link" to="/Services" onClick={() => handlePageChange('services')} >Security & Compliances</Link></li>
                     <li> <Link className="nav-link" to="/Services" onClick={() => handlePageChange('services')} >Virtualization</Link></li>
                     
                  </ul>
               </div>
               <div className="col-md-4 col-6 mb-2">
                  <h6 className="mb-3">Quick links</h6>
                  <ul>
                     
                     <li> <Link className="nav-link" to="/Partners" onClick={() => handlePageChange('Partners')} >Partners</Link></li>
                     <li><a href="https://webadmin.alexerp.com/" target='_blank'> Login</a></li>
                     {/* <li><a href="#">Home</a></li>
                     <li><a href="#">About</a></li> */}
                     
                  </ul>
               </div>
            </div>
         </div>
      </div>
      <div className='row'>
      <div className="social__icons mb-3">
               < WhatsAppButton />
            </div>
      </div>
      <div className="row border-top border-2 pt-1">
               <div className="col-md-5 order-md-0 order-1 text-md-start text-center">
                  <a className='fs-6'> © 2023 BCPL All Rights Reserved</a>
               </div>
               <div className="col-md-7 text-md-end text-center fs-6">
                  <Link to="./Privacy">Privacy Policy</Link> &nbsp;&nbsp; | &nbsp;&nbsp;
                  <Link to="./Terms">Terms & Conditions</Link>
               </div>
            </div>
   </div>
   {/* < FBButton /> */}
</footer>
</>
  );
}

export default Footer;

